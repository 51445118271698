.redirect-datepicker {
  input {
    font-size: 14px;
    border-color: transparent !important;
    border-radius: 0;
    background-color: $card-bg-3 !important;
    font-weight: $fw-5;
    @extend .text-center;
  }
  .rdtPicker {
    & > div {
      table {
        @extend .w-100;
        &:first-of-type {
          @extend .my-2;
          tr {
            @extend .justify-content-center;
            th {
              background-color: $card-bg-4;
              border-bottom: 3px solid $card-bg-3;
              &:hover {
                background-color: $card-bg-6;
              }
              @extend .p-2, .px-4, .mx-2;
            }
          }
        }
        &:nth-of-type(2) {
          tr {
            @extend .justify-content-around;
          }
        }
        tr {
          @extend .d-flex;
          &:nth-of-type(odd) {
            td {
              &:nth-of-type(odd) {
                border-bottom: 3px solid $card-bg-1;
                background-color: $card-bg-2;
              }
              &:nth-of-type(even) {
                border-bottom: 3px solid $card-bg-2;
                background-color: $card-bg-3;
              }
              &:hover {
                background-color: $card-bg-5;
              }
            }
          }
          &:nth-of-type(even) {
            td {
              &:nth-of-type(even) {
                border-bottom: 3px solid $card-bg-1;
                background-color: $card-bg-2;
              }
              &:nth-of-type(odd) {
                border-bottom: 3px solid $card-bg-2;
                background-color: $card-bg-3;
              }
              &:hover {
                background-color: $card-bg-5;
              }
            }
          }
          td {
            width: 25%;
            min-width: 80px;
            @extend .p-2, .py-4, .m-1;
            &.rdtActive {
              border-bottom-width: 0 !important;
              background-color: $card-bg-7 !important;
            }
          }
          td,
          tr,
          th {
            @extend .cursor-pointer, .text-center, .rounded;
          }
        }
      }
    }
  }
}
