html {
  width: 100% !important;
  overflow-y: scroll !important;
}

body {
  margin: 0;
  padding-top: 80px;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #112740 1px, transparent 1px),
    linear-gradient(to bottom, #112740 1px, #001831 1px);
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

*:focus {
  outline: 0 !important;
}

.modal-body {
  margin-top: 60px;
  padding: 20px 30px;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #112740 1px, transparent 1px),
    linear-gradient(to bottom, #112740 1px, #001831 1px);
  border-radius: 10px;
}

.carousel {
  .thumbs-wrapper {
    margin-bottom: 0;
  }

  .thumbs {
    text-align: center;
    padding: 0;
  }
}

.navbar-dark {
  background-color: #001831 !important;
  box-shadow: 0px 1px 5px #000;
}

.form-control {
  margin-bottom: 0.5rem;
  background: rgba(#000411, 0.5) !important;
  border: 1px solid rgba(#fff, 0.5) !important;
  outline: 0 !important;
  box-shadow: none !important;
  color: #fff !important;
  &.small {
    font-size: 12px;
    border: 1px solid rgba(#fff, 0.1) !important;
    height: 26px;
    background-color: rgba(0, 4, 17, 0.75) !important;
    border-radius: 2px !important;
  }
}

.card {
  background: rgba(#000411, 0.5) !important;
  box-shadow: 0px 0px 2px rgba(#fff, 0.8) !important;
  text-shadow: 0px 0px 2px rgba(#fff, 0.8);

  .react-monaco-editor-container {
    text-shadow: none;
  }
}

.nav-tabs {
  margin-bottom: 1rem;
  border-bottom: 0;

  .nav-link {
    color: #fff !important;
    border: 0 !important;
    border-radius: 0.25rem;
  }

  .nav-link.active {
    background: rgba(#000411, 0.5) !important;
    box-shadow: 0px 0px 2px rgba(#fff, 0.8) !important;
  }
}
