.card--light {
  background-color: rgba(#868992, 0.2);
  border: 1px solid rgba(#868992, 0.1);
  border-bottom: 2px solid rgba(#868992, 0.1);
  font-weight: 300;
  &:hover {
    background-color: rgba(#868992, 0.225);
  }
}

.text {
  &--light {
    color: rgba($light, 0.5);
    &:hover {
      color: rgba($light, 0.8);
    }
    &.danger {
      color: rgba($light-red, 0.6);
      &:hover {
        color: rgba($light-red, 0.8);
      }
    }
  }
  &-center {
    text-align: center;
  }
  &-uppercase {
    font-weight: $fw-5;
    text-transform: uppercase;
  }
  &-error {
    font-size: 11px;
    color: #d83e3e;
  }
}

.p {
  &-1 {
    padding: 0.25rem;
  }
  &-2 {
    padding: 0.5rem;
  }
  &-3 {
    padding: 0.75rem;
  }
  &-4 {
    padding: 1rem;
  }
  &x {
    &-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    &-4 {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &y {
    &-3 {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    &-4 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
.m {
  &-1 {
    margin: 0.15rem;
  }
  &-2 {
    margin: 0.25rem;
  }
  &x {
    &-2 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
  &y {
    &-2 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}

.w {
  &-25 {
    width: 25%;
  }
  &-50 {
    width: 50%;
  }
  &-75 {
    width: 75%;
  }
  &-100 {
    width: 100%;
  }
}

.d {
  &-flex {
    display: flex;
  }
}
.justify-content {
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-evenly {
    justify-content: space-evenly;
  }
  &-center {
    justify-content: center;
  }
}

.rounded {
  border-radius: 4px;
}

.btn {
  font-size: 13px;
  letter-spacing: 0.4px;
  font-weight: $fw-6;
  @extend .p-1, .px-4, .text-uppercase;
  &.btn-card {
    color: $white;
    background-color: $card-bg-3;
    border-bottom: 3px solid $card-bg-3;
    &:hover {
      background-color: $card-bg-6;
    }
  }
  &.small {
    font-size: 9px;
  }
}
