.casino {
  margin-bottom: 1rem;

  .image-container {
    height: 150px;
    width: 150px;
    background-image: linear-gradient(45deg, #112740 25%, transparent 25%), linear-gradient(-45deg, #112740 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #112740 75%), linear-gradient(-45deg, transparent 75%, #112740 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
}