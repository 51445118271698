$white: #fff;

$light: #b9c3ce;
$light-red: #e97777;

$card-bg: rgba(#000411, 0.5);
$card-bg-1: desaturate(lighten($card-bg, 12%), 30%);
$card-bg-2: desaturate(lighten($card-bg-1, 4%), 15%);
$card-bg-3: desaturate(lighten($card-bg-2, 4%), 8%);
$card-bg-4: desaturate(lighten($card-bg-3, 4%), 8%);
$card-bg-5: desaturate(lighten($card-bg-4, 4%), 8%);
$card-bg-6: desaturate(lighten($card-bg-5, 4%), 8%);
$card-bg-7: desaturate(lighten($card-bg-6, 4%), 8%);

$fw-1: 100;
$fw-2: 200;
$fw-3: 300;
$fw-4: 400;
$fw-5: 500;
$fw-6: 600;
$fw-7: 700;
